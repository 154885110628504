import React from "react";
import Logo from "../../assets/images/logo.png";
import "./index.css";

const Banner2 = () => {
  return (
    <>
      <div className="banner_two">
        <div className="textSection">
          <span>MORE</span>
          <span>BUY'S</span>
          <span>LESS SALE</span>
        </div>
        <div className="imageSection">
          <div className="box">
            <div className="boxHeader">
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <span className="buy">BUY BUY BUY BUY BUY BUY</span>
            </div>
            <div className="boxBody">
              <div className="whitetext">
                <span>GREEN</span>
                <span>WALL</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner2;
