import React from "react";
import Star from "../../assets/images/star.png";
import "./index.css";

const Banner5 = () => {
  return (
    <>
      <div className="banner_five">
        <div className="textSection">
          <div className="title">
            <img src={Star} alt="starIcon" className="firstStar" />
            RELIABLE
            <img src={Star} alt="starIcon" className="secondStar" />
          </div>
          <div className="title small">100% UP TIME!</div>
        </div>
      </div>
    </>
  );
};

export default Banner5;
