import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Layout from "../components/Layout";
import React from "react";
import About from "../pages/About";
import Service from "../pages/Service";
import ContactUs from "../pages/Contact";
import Pricing from "../pages/Pricing";
import AdsBanners from "../pages/Banners/Index";
const PublicRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/banners" element={<AdsBanners />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default PublicRoutes;
