import React from "react";
import { NavLink, useLocation } from "react-router-dom";

interface props {
  onClick?: any;
}
const Navigation: React.FC<props> = ({ onClick }) => {
  const location = useLocation();

  console.log(location);
  return (
    <>
      <ul className="navbar-nav ms-auto">
        <li
          className={
            location.pathname === "/" && location.hash == ""
              ? "nav-item active"
              : "nav-item"
          }
          onClick={onClick}
        >
          <a href="/" className="nav-link ">
            Home{" "}
          </a>
        </li>
        <li
          onClick={onClick}
          className={
            location.hash === "#pricing" ? "nav-item active" : "nav-item"
          }
        >
          <a href="#pricing" className="nav-link">
            Pricing{" "}
          </a>
        </li>
        <li
          onClick={onClick}
          className={
            location.hash === "#calculator" ? "nav-item active" : "nav-item"
          }
        >
          <a href="#calculator" className="nav-link ">
            Calculator{" "}
          </a>
        </li>
        <li
          onClick={onClick}
          className={
            location.hash === "#features" ? "nav-item active" : "nav-item"
          }
        >
          <a href="#features " className="nav-link ">
            Features{" "}
          </a>
        </li>
        <li className="nav-item" onClick={onClick}>
          <a className="nav-link lets_talk" href="#contact">
            Let's Talk<i className="circle fa-regular fa-angle-right"></i>
          </a>
        </li>
      </ul>
    </>
  );
};
export default Navigation;
