import React from "react";
import Logo from "../../assets/images/logo.png";
import "./index.css";

const Banner1 = () => {
  return (
    <>
      <div className="banner_one">
        <div className="logoSection">
          <img src={Logo} alt="logo" />
          <div className="title">BOT</div>
        </div>
        <div className="textSection">
          <span>SOLANA</span>
          <span>VOLUME</span>
          <span>BOOSTER</span>
        </div>
      </div>
    </>
  );
};

export default Banner1;
