import React from "react";
import ChooseImage from "../../assets/images/new-version1.webp";
import Heading from "../../components/Heading";
import Button from "../../components/Button";
import bg1 from "../../assets/images/choose-backgroundimage1.png";
import bg2 from "../../assets/images/choose-backgroundimage2.png";
const ChooseSection: React.FC = () => {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <figure className="choose-backgroundimage1 mb-0">
            <img src={bg1} className="img-fluid" alt="" />
          </figure>
          <figure className="choose-backgroundimage2 mb-0">
            <img src={bg2} className="img-fluid" alt="" />
          </figure>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 d-md-block d-none"></div>
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="content" data-aos="fade-up">
                <Heading tag="h6" text="It's so easy. Try it!" />
                <Heading tag="h2" text="Set up VBot directly on Telegram." />
                <p className="text-size-18">
                  Discover the robust features of VBot, now easily accessible
                  via Telegram. Engage with a user-friendly bot designed to
                  boost your Crypto project’s success. Ready to unlock its
                  potential? Click 'Access Bot' to embark on your journey
                  towards significant growth. Your path to success is just a few
                  clicks away!,{" "}
                </p>
                <Button
                  link="https://t.me/SolanaVolumeBoosterMmVBot"
                  target="_blank"
                  classes="read_more"
                  text="Access Bot"
                />
              </div>
            </div>
          </div>
          <div className="choose_wrapper position-relative">
            <figure className="choose-image mb-0">
              <img src={ChooseImage} className="img-fluid" alt="" />
            </figure>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChooseSection;
