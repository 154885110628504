import React, { useState, useEffect } from "react";
import ContactBackgroundimage1 from "../../assets/images/contact-backgroundimage1.png";
import ContactBackgroundimage2 from "../../assets/images/contact-backgroundimage2.png";
import ContactImage from "../../assets/images/new-version-2.webp";
import ContactCircle from "../../assets/images/contact-circle.png";
import Button from "../../components/Button";
const ContactSection: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const submitHandler = (e: any) => {
    e.preventDefault();
    setShowMessage(true);
  };
  return (
    <>
      <section className="contact-section" id="contact">
        <div className="container">
          <figure className="contact-backgroundimage1 mb-0">
            <img src={ContactBackgroundimage1} className="img-fluid" alt="" />
          </figure>
          <figure className="contact-backgroundimage2 mb-0">
            <img src={ContactBackgroundimage2} className="img-fluid" alt="" />
          </figure>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="content" data-aos="fade-up">
                <h6>Have any Qureries?</h6>
                <h2>Dont hesitate to contact us!</h2>
                {showMessage && (
                  <div className="alertMessage">
                    Your message has been sent successfully.
                  </div>
                )}
                <form id="contactpage" onSubmit={submitHandler}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form_style input1"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form_style"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group message">
                    <textarea
                      className="form_style"
                      placeholder="Message"
                      rows={3}
                      onChange={(e) => setMsg(e.target.value)}
                      required
                    >
                      {msg}
                    </textarea>
                  </div>
                  <div className="buttonsSection">
                    <button type="submit" className="send_now">
                      Send Email
                      <i className="circle fa-regular fa-angle-right"></i>
                    </button>
                    <Button
                      link="https://t.me/VBotSolanaVolumeBooster"
                      target="_blank"
                      classes="send_now teleGramBtn"
                      text="Contact by Telegram"
                      iconClass="circle fa-sharp fa-solid fa-paper-plane"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="contact_wrapper position-relative">
                <figure className="contact-image mb-0">
                  <img src={ContactImage} className="img-fluid" alt="" />
                </figure>
                <a
                  href="https://t.me/+HofVwBPnu30wZmU9"
                  className="icon1 mb-0 text-decoration-none"
                >
                  <i className="circle fa-solid fa-mobile-screen-button"></i>
                </a>
                <a
                  href="mailto:contact@vbot.bot"
                  className="icon2 mb-0 text-decoration-none"
                >
                  <i className="circle fa-solid fa-envelope"></i>
                </a>
                <figure className="contact-circle mb-0">
                  <img src={ContactCircle} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
