import React, { useState, useEffect } from "react";
import Switch from "../../components/Switch";
import PackageBox from "../../components/PackageBox";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import BootCalculator from "../../components/BootCalculator";
import carIcon from "../../assets/icons/car.svg";
import arrowIcon from "../../assets/icons/arrowsvg.svg";
import bulletIcon from "../../assets/icons/bullter.svg";
import rocketIcon from "../../assets/icons/rocket-fly-1.svg";
import bg1 from "../../assets/images/contact-backgroundimage1.png";
import bg2 from "../../assets/images/contact-backgroundimage2.png";
const Pricing: React.FC = () => {
  const [selectedType, setSelectedType] = useState("starter");
  const [time, setTime] = useState("6h");
  const [packageName, setPackageName] = useState("Starter Booster 8 Sol");
  const [calculatorValue, setCalculatorValue] = useState<number>(1);
  const [nanoCalculatorValue, setNanoCalculatorValue] = useState<number>(1);
  const [estimateVolume, setEstimateVolume] = useState<number>(0.34);
  const [solanaPrice, setSolanaPrice] = useState<any>(237);
  const [nanoBotType, setNanoBotType] = useState("A");
  const [estimateNanoVolume, setEstimateNanoVolume] = useState<number>(0);
  const handleValueChange = (newValue: number) => {
    setCalculatorValue(newValue);
  };

  const handleSelectType = (type: string) => {
    setSelectedType(type);
  };

  const handleSelectTime = (changedTime: string) => {
    setTime(changedTime);
  };

  const getSolanaPrice = async () => {
    const cachedPrice = getCookie("solanaPrice");

    if (cachedPrice) {
      setSolanaPrice(cachedPrice);
    } else {
      const url =
        "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd";
      try {
        const response = await fetch(url);
        const data = await response.json();
        const solanaPrice = data.solana.usd;
        if (solanaPrice > 0) {
          setCookie("solanaPrice", solanaPrice, 1);
          setSolanaPrice(solanaPrice);
        }
      } catch (error) {
        console.error("Error fetching Solana price:", error);
      }
    }
  };

  const formatAmount = (amount: number): string => {
    const scaledAmount = amount; // Scale to match 1 = 1M

    if (scaledAmount >= 1_000_000) {
      return (scaledAmount / 1_000_000).toFixed(2) + "M";
    } else if (scaledAmount >= 1_000) {
      return (scaledAmount / 1_000).toFixed(0) + "K";
    } else {
      return scaledAmount.toFixed(2);
    }
  };

  useEffect(() => {
    let volume;
    switch (selectedType) {
      case "starter":
        volume = solanaPrice * 2250;
        break;
      case "sigma":
        volume = solanaPrice * 4500;
        break;
      case "alpha":
        volume = solanaPrice * 7500;
        break;
      case "enterprise":
        volume = solanaPrice * 15000;
        break;
      default:
        volume = solanaPrice * 2250;
    }

    setEstimateVolume(volume);
  }, [selectedType]);

  useEffect(() => {
    getSolanaPrice();
  }, []);

  useEffect(() => {
    setEstimateNanoVolume(solanaPrice * 250);
  }, [solanaPrice]);

  const handleNanoValueChange = (newValue: number) => {
    setNanoCalculatorValue(newValue);
  };

  // Set cookie with expiration of 1 day
  const setCookie = (name: any, value: any, days: any) => {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
  };

  // Get cookie by name
  const getCookie = (name: any) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  return (
    <>
      <div className="section_pricing" id="pricing">
        <figure className="pricing-backgroundimage1 mb-0">
          <img decoding="async" src={bg1} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage2 mb-0">
          <img decoding="async" src={bg2} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage3 mb-0">
          <img decoding="async" src={bg1} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage4 mb-0">
          <img decoding="async" src={bg2} className="img-fluid" alt="" />
        </figure>
        <div className="position-relative">
          <div className="container">
            <div className="priceingHeader">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <p data-aos="fade-up">
                    Discover our tailored packages to rapidly increase your
                    project's trading volume and holder count. Choose your
                    package now to get started!
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 d-flex justify-content-center">
                  <Switch handleSelectTime={handleSelectTime} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                  <p className="rightText" data-aos="fade-up">
                    *VBot operates as long as there are funds in the wallet.
                    Depending on the selected mode, it consumes SOL to cover
                    Raydium fees every 6 or 24 hours. Please note that network
                    conditions and a decreasing token price may cause the funds
                    to be depleted more quickly.
                  </p>
                </div>
              </div>
            </div>
            <div className="pricingBody">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 package-col">
                  <PackageBox
                    type="starter"
                    onClick={() => {
                      handleSelectType("starter");
                      setPackageName("Starter Package 8 Sol");
                    }}
                    selectedType={selectedType}
                    time={time}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 package-col">
                  <PackageBox
                    type="sigma"
                    onClick={() => {
                      handleSelectType("sigma");
                      setPackageName("Sigma Plan 16 Sol");
                    }}
                    selectedType={selectedType}
                    time={time}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 package-col">
                  <PackageBox
                    type="alpha"
                    onClick={() => {
                      handleSelectType("alpha");
                      setPackageName("Alpha Pack 27 Sol");
                    }}
                    selectedType={selectedType}
                    time={time}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6 package-col">
                  <PackageBox
                    type="enterprise"
                    onClick={() => {
                      handleSelectType("enterprise");
                      setPackageName("Enterprise Booster 54 Sol");
                    }}
                    selectedType={selectedType}
                    time={time}
                  />
                </div>
              </div>

              <div className="instruction" id="calculator">
                <ul>
                  <li>
                    <sup>1</sup>Amount of fresh wallets used to create a volume
                  </li>
                  <li>
                    <sup>2</sup>The number of transactions per minute might be
                    slightly different depending on network congestion.{" "}
                  </li>
                  <li>
                    <sup>3</sup>VBot does not charge any extra trading fees on
                    top of transactions.
                  </li>
                </ul>
              </div>
              <div className="calculatorSectionMain">
                <div className="row align-items-start">
                  <div className="col-md-4 col-sm-12 col-12">
                    <div className="calculatorTable">
                      <h2>
                        Calculate <span>your estimate volume</span>
                      </h2>
                      <p>
                        Get an instant estimate of your trading volume potential
                        with Discover the power of your project's growth using
                        the calculator!
                      </p>
                      <a
                        href="https://t.me/SolanaVolumeBoosterMmVBot"
                        target="_blank"
                      >
                        <button className="accessBtn">
                          <span>
                            Access VBot
                            <i className="circle fa-regular fa-angle-right"></i>
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12 col-12">
                    <div className="calculatorSection">
                      <div className="packagBlock">
                        <div className="packageDetail">
                          Main Package:
                          <span>{packageName}</span>
                        </div>
                        <div className="packages">
                          <ul>
                            <li>
                              <div
                                className={`iconBox ${selectedType == "starter" && "selected"}`}
                                onClick={() => {
                                  handleSelectType("starter");
                                  setPackageName("Starter Booster 8 Sol");
                                }}
                              >
                                <img src={carIcon} alt="" className="icon" />
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox ${selectedType == "sigma" && "selected"}`}
                                onClick={() => {
                                  handleSelectType("sigma");
                                  setPackageName("Sigma Plan 16 Sol");
                                }}
                              >
                                <img src={arrowIcon} alt="" className="icon" />
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox ${selectedType == "alpha" && "selected"}`}
                                onClick={() => {
                                  handleSelectType("alpha");
                                  setPackageName("Alpha Pack 27 Sol");
                                }}
                              >
                                <img src={bulletIcon} alt="" className="icon" />
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox ${selectedType == "enterprise" && "selected"}`}
                                onClick={() => {
                                  handleSelectType("enterprise");
                                  setPackageName("Enterprise Booster 54 Sol");
                                }}
                              >
                                <img src={rocketIcon} alt="" className="icon" />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="packagBlock calculatorBlock">
                        <div className="packageDetail">
                          Your token price change
                        </div>
                        <div className="packages">
                          <BootCalculator
                            packageType={selectedType}
                            onValueChange={handleValueChange}
                          />
                        </div>
                      </div>

                      <div className="packagBlock">
                        <div className="packageDetail">Solana Price</div>
                        <div className="packages">
                          <span>${solanaPrice}</span>
                        </div>
                      </div>

                      <div className="packagBlock volume">
                        <div className="packageDetail">
                          <span>Estimate Volume Boost</span>
                        </div>
                        <div className="packages">
                          <span className="volumeBoost">
                            ${formatAmount(estimateVolume * calculatorValue)}
                          </span>
                        </div>
                      </div>
                      <div className="packagBlock">
                        <div className="packageDetail">
                          <span>NanoBot Add On:</span>
                        </div>
                        <div className="packages">
                          <ul>
                            <li>
                              <div
                                className={`iconBox nanoBot  ${nanoBotType == "A" && "selected"}`}
                                onClick={() => {
                                  setNanoBotType("A");
                                  setEstimateNanoVolume(solanaPrice * 250);
                                }}
                              >
                                A
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox nanoBot  ${nanoBotType == "B" && "selected"}`}
                                onClick={() => {
                                  setNanoBotType("B");
                                  setEstimateNanoVolume(solanaPrice * 300);
                                }}
                              >
                                B
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox nanoBot  ${nanoBotType == "C" && "selected"}`}
                                onClick={() => {
                                  setNanoBotType("C");
                                  setEstimateNanoVolume(solanaPrice * 375);
                                }}
                              >
                                C
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox nanoBot  ${nanoBotType == "D" && "selected"}`}
                                onClick={() => {
                                  setNanoBotType("D");
                                  setEstimateNanoVolume(solanaPrice * 500);
                                }}
                              >
                                D
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox nanoBot  ${nanoBotType == "E" && "selected"}`}
                                onClick={() => {
                                  setNanoBotType("E");
                                  setEstimateNanoVolume(solanaPrice * 625);
                                }}
                              >
                                E
                              </div>
                            </li>
                            <li>
                              <div
                                className={`iconBox nanoBot ${nanoBotType == "F" && "selected"}`}
                                onClick={() => {
                                  setNanoBotType("F");
                                  setEstimateNanoVolume(solanaPrice * 750);
                                }}
                              >
                                F
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="packagBlock calculatorBlock">
                        <div className="packageDetail">
                          Your token price change
                        </div>
                        <div className="packages">
                          <BootCalculator
                            packageType={selectedType}
                            onValueChange={handleNanoValueChange}
                          />
                        </div>
                      </div>

                      <div className="packagBlock volume">
                        <div className="packageDetail">
                          <span>Estimate Volume Boost</span>
                        </div>
                        <div className="packages">
                          <span className="volumeBoost">
                            $
                            {formatAmount(
                              estimateNanoVolume * nanoCalculatorValue
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Pricing;
