import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import MicroBoatGrid from "../../components/MicroBoatGrid";
import Image1 from "../../assets/images/nano-robo1.webp";
import Image2 from "../../assets/images/nano-robo2.webp";
import Image3 from "../../assets/images/nano-robo3.webp";
import Image4 from "../../assets/images/nano-robo4.webp";
import bg1 from "../../assets/images/contact-backgroundimage1.png";
import bg2 from "../../assets/images/contact-backgroundimage2.png";

const MicroBotSection = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      const items = document.querySelectorAll(".equalHeightItemMicro");
      let maxHeight = 0;

      setTimeout(() => {
        items.forEach((item) => {
          maxHeight = Math.max(maxHeight, item.clientHeight);
        });

        items.forEach((item) => {
          if (item instanceof HTMLElement) {
            item.style.minHeight = `${maxHeight}px`;
          }
        });
      }, 1000);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePlay = () => {
    setModalOpen(true);
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 100);
  };

  const handleClose = () => {
    setModalOpen(false);

    // Exit fullscreen and pause the video
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video
    }
  };

  const videoUrl = "/video/nanobot-preview.mp4";
  return (
    <>
      <div className="section_pricing microBotsSection" id="microBots">
        <figure className="pricing-backgroundimage1 mb-0">
          <img decoding="async" src={bg1} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage2 mb-0">
          <img decoding="async" src={bg2} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage3 mb-0">
          <img decoding="async" src={bg1} className="img-fluid" alt="" />
        </figure>
        <figure className="pricing-backgroundimage4 mb-0">
          <img decoding="async" src={bg2} className="img-fluid" alt="" />
        </figure>
        <div className="container ">
          <div className="position-relative">
            <div className="container">
              <div className="microBotsheadingSection">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="microBotList">
                      <ul>
                        <li>
                          NanoBot A <span></span> 1 SOL
                        </li>
                        <li>
                          NanoBot B <span></span> 1.2 SOL
                        </li>
                        <li>
                          NanoBot C <span></span> 1.5 SOL
                        </li>
                        <li>
                          NanoBot D <span></span> 2 SOL
                        </li>
                        <li>
                          NanoBot E <span></span> 2.5 SOL
                        </li>
                        <li>
                          NanoBot F <span></span> 3 SOL
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <h2>
                      Order Multiple
                      <br />
                      Nano<span>Bots</span> at the same time
                    </h2>
                    <p>
                      Highly recommended addition to the main package. With
                      NanoBots you can significantly increase transaction
                      amount, volume, and project visibility. MicroBots operate
                      for approximately 50% of main package lifespan. So 12
                      hours (on 24h package) and 3 hours on (6h package).
                      NanoBot can only be purchased as a addition to the the
                      main package.{" "}
                    </p>
                    <div className="microBotsheadingCta">
                      {/* <a href="https://t.me/+HofVwBPnu30wZmU9" target="_blank">
                        <button className="accessBtn">
                          <span>
                            Access VBot
                            <i className="circle fa-regular fa-angle-right"></i>
                          </span>
                        </button>
                      </a> */}

                      <a className="popup-vimeo" onClick={handlePlay}>
                        <i
                          className="thumb play-icon fa-solid fa-play"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </a>
                      <div className="previewtext">Preview of NanoBots </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="microBotMainSection">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="sectionheading">
                      <h2>
                        FOMO effect with Micro<span>Bots</span>
                      </h2>
                      <p>
                        Combine up to 6 NanoBots to create transactions of
                        different sizes, making your chart look more natural.
                        Each NanoBot adds 2 extra transactions per minute. So,
                        with 6 NanoBots, you'll get 12 extra transactions per
                        minute. Additionally they will more than double the
                        volume creation of a 9 SOL starter package!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <MicroBoatGrid
                    image={Image1}
                    title="Additional transactions"
                    description="Each NanoBot makes additional two transactions per minute."
                  />

                  <MicroBoatGrid
                    image={Image2}
                    title="Greater visibility to you'r project"
                    description="More transactions to create a bigger FOMO effect and lure auto trading bots."
                  />

                  <MicroBoatGrid
                    image={Image3}
                    title="Magnet to organic volume"
                    description="More organic volume due to increased rate and variety of transactions."
                  />

                  <MicroBoatGrid
                    image={Image4}
                    title="Greater performance"
                    description="Improved transaction variants and overall performance of V-Bot MM."
                  />
                </div>
                <div className="microBotsheadingCta bottom">
                  <a
                    href="https://t.me/SolanaVolumeBoosterMmVBot"
                    target="_blank"
                  >
                    <button className="accessBtn">
                      <span>
                        Access VBot
                        <i className="circle fa-regular fa-angle-right"></i>
                      </span>
                    </button>
                  </a>
                  <a className="popup-vimeo bottom" onClick={handlePlay}>
                    <i
                      className="thumb play-icon fa-solid fa-play"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </a>
                  <div className="previewtext">Preview of NanoBots </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div style={modalStyles.overlay} onClick={handleClose}>
          <video
            ref={videoRef}
            src={videoUrl}
            controls
            style={modalStyles.video}
          />
          <button onClick={handleClose} style={modalStyles.closeButton}>
            &times;
          </button>
        </div>
      )}
    </>
  );
};

const modalStyles = {
  overlay: {
    position: "fixed" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  video: {
    maxWidth: "100%",
    maxHeight: "100%",
    outline: "none",
  },
  closeButton: {
    position: "absolute" as const,
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "30px",
    color: "#fff",
    cursor: "pointer",
    zIndex: 10000,
  },
};

export default MicroBotSection;
