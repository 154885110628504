// PackageBox.tsx
import React from "react";
import packageImg1 from "../../assets/images/img1-1.webp";
import packageImg2 from "../../assets/images/sigma-image.webp";
import packageImg3 from "../../assets/images/alpha-image.webp";
import packageImg4 from "../../assets/images/img4-1.webp";

import "./index.scss";

interface Props {
  type?: string | null;
  selectedType: string | null;
  onClick: (selectedType: string) => void;
  time?: string;
}

const PackageBox: React.FC<Props> = ({ type, selectedType, onClick, time }) => {
  const handleClick = () => {
    if (type) {
      onClick(type);
    }
  };

  const renderContent = () => {
    switch (type) {
      case "starter":
        return (
          <div
            className={`packageBoxMain  ${selectedType === "starter" ? "selected" : ""}`}
            onClick={handleClick}
          >
            <div className="packageBoxHeader">
              <div className="heading">Starter Booster</div>
            </div>
            <div className="packageBoxBody">
              <div className="subheading">
                Great for new or small projects striving to achieve a major
                impact.
              </div>
              <div className="packageDescription">
                Multiplier: <sup>1</sup> <span className="colorBlue"> No</span>
                <br />
                <span className="colorBlue">2200</span> wallets<sup>1</sup>{" "}
                <br />
                <span className="colorBlue">
                  {time == "24h" ? "3 tx" : "6 tx"}{" "}
                </span>{" "}
                minute<sup>2</sup> <br />
                <span className="colorBlue">0.4%</span> trading fees{" "}
                <sup>3</sup>
              </div>
              <div className="price">
                8 <div className="currency">Sol</div>
              </div>
            </div>
          </div>
        );
      case "sigma":
        return (
          <div
            className={`packageBoxMain ${selectedType === "sigma" ? "selected" : ""}`}
            onClick={handleClick}
          >
            <div className="packageBoxHeader sigma">
              <div className="heading">Sigma Plan</div>
            </div>
            <div className="packageBoxBody">
              <div className="subheading">
                Designed for projects poised to elevate their visibility and
                capture greater attention.
              </div>
              <div className="packageDescription">
                <div className="packageDescription">
                  Multiplier: <sup>1</sup>{" "}
                  <span className="colorBlue"> Yes</span>
                  <br />
                  <span className="colorBlue">4400</span> wallets<sup>1</sup>{" "}
                  <br />
                  <span className="colorBlue">
                    {time == "24h" ? "6 tx" : "15 tx"}{" "}
                  </span>{" "}
                  minute<sup>2</sup> <br />
                  <span className="colorBlue">0.3%</span> trading fees{" "}
                  <sup>3</sup>
                </div>
              </div>
              <div className="price colorBlue">
                16 <div className="currency">Sol</div>
              </div>
            </div>
          </div>
        );
      case "alpha":
        return (
          <div
            className={`packageBoxMain ${selectedType === "alpha" ? "selected" : ""}`}
            onClick={handleClick}
          >
            <div className="packageBoxHeader alpha">
              <div className="heading">Alpha Package</div>
            </div>
            <div className="packageBoxBody">
              <div className="subheading">
                Designed for projects looking to significantly boost their
                visibility and make an impact.
              </div>
              <div className="packageDescription">
                Multiplier: <span className="colorBlue">Yes</span> <br />
                <span className="colorBlue">7300</span> wallets<sup>1</sup>{" "}
                <br />
                <span className="colorBlue">
                  {time == "24h" ? "10 tx" : "30 tx"}
                </span>{" "}
                per minute<sup>2</sup> <br />
                <span className="colorBlue">0.2%</span> trading fees{" "}
                <sup>3</sup> <br />
              </div>
              <div className="price colorBlue">
                27 <div className="currency">Sol</div>
              </div>
            </div>
          </div>
        );
      case "enterprise":
        return (
          <div
            className={`packageBoxMain ${selectedType === "enterprise" ? "selected" : ""}`}
            onClick={handleClick}
          >
            <div className="packageBoxHeader enterprise">
              <div className="heading">Ecosystem Integration</div>
            </div>
            <div className="packageBoxBody">
              <div className="subheading">
                The ultimate package for visionary projects that are set to lead
                in the Cryptospace.
              </div>
              <div className="packageDescription">
                Multiplier: <span className="colorBlue">Yes</span> <br />
                <span className="colorBlue">14600</span> wallets<sup>1</sup>{" "}
                <br />
                <span className="colorBlue">
                  {time == "24h" ? "14 tx" : "60 tx"}
                </span>{" "}
                per minute<sup>2</sup> <br />
                <span className="colorBlue">0.1%</span> trading fees{" "}
                <sup>3</sup> <br />
              </div>
              <div className="price colorBlue">
                54 <div className="currency">Sol</div>
              </div>
            </div>
          </div>
        );
      default:
        return <div className="packageBoxMain">No package available</div>;
    }
  };

  return <>{renderContent()}</>;
};

export default PackageBox;
