import React, { useState, useRef } from "react";

interface Props {
  videoUrl: string;
}

const VideoPopup: React.FC<Props> = ({ videoUrl }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlay = () => {
    setModalOpen(true);

    // Automatically play and trigger fullscreen
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
        // if (videoRef.current.requestFullscreen) {
        //   videoRef.current.requestFullscreen();
        // } else if ((videoRef.current as any).webkitEnterFullscreen) {
        //   (videoRef.current as any).webkitEnterFullscreen();
        // } else if ((videoRef.current as any).mozRequestFullScreen) {
        //   (videoRef.current as any).mozRequestFullScreen();
        // } else if ((videoRef.current as any).msRequestFullscreen) {
        //   (videoRef.current as any).msRequestFullscreen();
        // }
      }
    }, 100);
  };

  const handleClose = () => {
    setModalOpen(false);

    // Exit fullscreen and pause the video
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video
    }
  };

  return (
    <>
      <div className="video-icon position-absolute">
        <div
          className="icon"
          onClick={handlePlay}
          style={{ cursor: "pointer" }}
        >
          <span>Watch</span>
          <a className="popup-vimeo">
            <i
              className="thumb play-icon fa-solid fa-play"
              style={{ cursor: "pointer" }}
            ></i>
          </a>
        </div>
        <span className="text">Our Video</span>
      </div>

      {isModalOpen && (
        <div style={modalStyles.overlay} onClick={handleClose}>
          <video
            ref={videoRef}
            src={videoUrl}
            controls
            style={modalStyles.video}
          />
          <button onClick={handleClose} style={modalStyles.closeButton}>
            &times;
          </button>
        </div>
      )}
    </>
  );
};

const modalStyles = {
  overlay: {
    position: "fixed" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  video: {
    maxWidth: "100%",
    maxHeight: "100%",
    outline: "none",
  },
  closeButton: {
    position: "absolute" as const,
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "30px",
    color: "#fff",
    cursor: "pointer",
    zIndex: 10000,
  },
};

export default VideoPopup;
