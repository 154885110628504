import React, { useState, useEffect } from "react";
import AboutImage from "../../assets/images/MAIN-PICTURE-FINAL-compressed-2.webp";
import Heading from "../../components/Heading";
import Button from "../../components/Button";
import Image1 from "../../assets/images/girdimg1.webp";
import Image2 from "../../assets/images/girdimg2.webp";
import Image3 from "../../assets/images/girdimg3.webp";
import Image4 from "../../assets/images/girdimg4.webp";
import "./index.scss";

const AboutSectionWithGrid: React.FC = () => {
  const [showGridOne, setShowGridOne] = useState(false);
  const [showGridTwo, setShowGridTwo] = useState(false);
  const [showGridThree, setShowGridThree] = useState(false);
  const [showGridFour, setShowGridFour] = useState(false);
  useEffect(() => {
    const adjustHeights = () => {
      const items = document.querySelectorAll(".equalHeightItemAbout");
      let maxHeight = 0;
      items.forEach((item) => {
        if (item instanceof HTMLElement) {
          item.style.minHeight = "auto";
        }
      });

      items.forEach((item) => {
        maxHeight = Math.max(maxHeight, item.clientHeight);
      });
      items.forEach((item) => {
        if (item instanceof HTMLElement) {
          item.style.minHeight = `${maxHeight}px`;
        }
      });
    };

    adjustHeights();
    window.addEventListener("resize", adjustHeights);

    return () => {
      window.removeEventListener("resize", adjustHeights);
    };
  }, [showGridOne, showGridTwo, showGridThree, showGridFour]);

  return (
    <section className="about-section">
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="about_wrapper">
              <figure className="mb-0 about-image aos-init" data-aos="fade-up">
                <img className="img-fluid" src={AboutImage} alt="Banner" />
              </figure>
              <div className="box">
                <div className="year">
                  <div className="number">
                    <span className="mb-0 value counter">VBot</span>
                    <sup className="mb-0 plus">+</sup>
                  </div>
                  <span className="mb-0 text">
                    - The ultimate tool for crypto owners.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="about_content" data-aos="fade-up">
              <h6 className="moveAnimate">About VBot</h6>
              <div className="gridMain">
                <div className="vbGird">
                  <div className="vbGirdCard equalHeightItemAbout">
                    <div className="cardImage">
                      <img src={Image1} alt="" className="src" />
                    </div>
                    <div className="cardHeading">
                      <Heading
                        tag="h3"
                        text="Unique dollar values & green wall on the chart"
                      />
                    </div>
                    <p className="cardDescription text-size-16">
                      V-Bot executes four times more buy transactions than sell
                      transactions (4:1) ratio
                    </p>
                    {!showGridOne && (
                      <span
                        className="readMoreCta"
                        onClick={() => setShowGridOne(true)}
                      >
                        {" "}
                        Read More...
                      </span>
                    )}
                    <p
                      className={`cardDescription text-size-16 readMoreText ${showGridOne && "show"}`}
                    >
                      , creating a noticeable 'green wall' effect on the chart
                      while ensuring all transactions have unique dollar values.
                      Making the chart appear completely organic.
                    </p>
                  </div>
                </div>
                <div className="vbGird">
                  <div className="vbGirdCard equalHeightItemAbout">
                    <div className="cardImage">
                      <img src={Image2} alt="" className="src" />
                    </div>
                    <div className="cardHeading">
                      <Heading
                        tag="h3"
                        text="Human alike wallet interactions"
                      />
                    </div>
                    <p className="cardDescription text-size-16">
                      Each sell transaction is always a wallets second
                      interaction with the contract. Unlike other
                    </p>
                    {!showGridTwo && (
                      <span
                        className="readMoreCta"
                        onClick={() => setShowGridTwo(true)}
                      >
                        {" "}
                        Read More...
                      </span>
                    )}
                    <p
                      className={`cardDescription text-size-16 readMoreText ${showGridTwo && "show"}`}
                    >
                      {" "}
                      primitive bots that have their sell transaction showing as
                      first and only interaction on their wallet, clearly
                      indicating bot activity, V-Bot keeps operations discreet
                      and natural.
                    </p>
                  </div>
                </div>
                <div className="vbGird">
                  <div className="vbGirdCard equalHeightItemAbout">
                    <div className="cardImage">
                      <img src={Image3} alt="" className="src" />
                    </div>
                    <div className="cardHeading">
                      <Heading tag="h3" text="Permanent Token Holders" />
                    </div>
                    <p className="cardDescription text-size-16">
                      V-Bot is simulating long term holders indefinitely with
                      each transaction through its{" "}
                    </p>
                    {!showGridThree && (
                      <span
                        className="readMoreCta"
                        onClick={() => setShowGridThree(true)}
                      >
                        {" "}
                        Read More...
                      </span>
                    )}
                    <p
                      className={`cardDescription text-size-16 readMoreText ${showGridThree && "show"}`}
                    >
                      wallets. Each package guarantees at least 2000+ additional
                      indefinite token holders. The number wallets you will
                      order directly match the number of token holders you will
                      receive.
                    </p>
                  </div>
                </div>
                <div className="vbGird">
                  <div className="vbGirdCard equalHeightItemAbout">
                    <div className="cardImage">
                      <img src={Image4} alt="" className="src" />
                    </div>
                    <div className="cardHeading">
                      <Heading tag="h3" text="Reliabie" />
                    </div>
                    <p className="cardDescription text-size-16">
                      V-Bot with it’s backup servers and retry mechanisms is
                      operating at 100% uptime
                    </p>
                    {!showGridFour && (
                      <span
                        className="readMoreCta"
                        onClick={() => setShowGridFour(true)}
                      >
                        {" "}
                        Read More...
                      </span>
                    )}
                    <p
                      className={`cardDescription text-size-16 readMoreText ${showGridFour && "show"}`}
                    >
                      and success rate. We implemented it after seeing the
                      competitive bot’s crash when the market get volatile. Be
                      worry free won't happen with V-Bot.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSectionWithGrid;
