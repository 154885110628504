import React from "react";
import Star from "../../assets/images/star.png";
import "./index.css";

const Banner4 = () => {
  return (
    <>
      <div className="banner_four">
        <div className="textSection">
          <div className="title">TOKEN</div>
          <div className="title small">
            <span className="greenText">
              <img src={Star} alt="starIcon" className="firstStar" />
              HOLDERS
              <img src={Star} alt="starIcon" className="secondStar" />
            </span>
            STAY!
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner4;
