import React from "react";
import Star from "../../assets/images/star.png";
import "./index.css";
const Banner3 = () => {
  return (
    <>
      <div className="banner_three">
        <div className="textSection">
          <div className="title">
            GET{" "}
            <span className="greenText">
              <img src={Star} alt="starIcon" className="firstStar" />
              MORE
              <img src={Star} alt="starIcon" className="secondStar" />
            </span>
          </div>
          <div className="title small">VOLUME FOR $OL</div>
        </div>
      </div>
    </>
  );
};
export default Banner3;
