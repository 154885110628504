import React from "react";
import Star from "../../assets/images/star.png";
import "./index.css";

const Banner6 = () => {
  return (
    <>
      <div className="banner_six">
        <div className="textSection">
          <div className="title zoomIn">
            <img src={Star} alt="starIcon" className="firstStar" />
            RYADIUM-ALL
          </div>
          <div className="title">
            <img src={Star} alt="starIcon" className="secondStar" />
            <span className="textWhite tumble">PUMPFUN</span>
            <img src={Star} alt="starIcon" className="thirdStar" />
            <span className="textPink zoomIn">METEORA</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner6;
