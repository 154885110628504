import React from "react";

const MicroBoatGrid = ({ image, title, description }: any) => {
  return (
    <>
      <div className="col-lg-3 col-md-6 col-sm-6 col-12">
        <div className="featureBox equalHeightItemMicro">
          <img src={image} alt="" />
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </>
  );
};

export default MicroBoatGrid;
