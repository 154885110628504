import React from "react";
import BannerUpperdots from "../../assets/images/banner-upperdots.png";
import BannerLowerdots from "../../assets/images/banner-lowerdots.png";
import BannerImage from "../../assets/images/banner-image-1-2-130.webp";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import VideoPopup from "../../components/VideoPopup";
import { HomeBannerParams } from "../../api/apiSchema";

const BannerSection: React.FC = () => {
  const videoUrl = "/video/solana-updated-video.mp4";

  return (
    <section className="banner-section">
      <div className="container position-relative">
        <VideoPopup videoUrl={videoUrl} />
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <div className="banner_content">
              <Heading text="Welcome to VBot" tag="h6" />
              <Heading
                text="VBot - Take your crypto project to another level"
                tag="h1"
              />
              <Heading
                classes="text-white"
                tag="h4"
                text="Most advanced bot created for crypto project owners"
              />
              <Button
                classes="explore"
                text="Access VBot"
                link="https://t.me/SolanaVolumeBoosterMmVBot"
                target="_blank"
              />
            </div>
          </div>
          <strong>
            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
              <div className="banner_wrapper">
                <figure className="mb-0 banner-upperdot">
                  <img className="img-fluid" src={BannerUpperdots} alt="" />
                </figure>
                <figure className="mb-0 banner-lowerdot">
                  <img className="img-fluid" src={BannerLowerdots} alt="" />
                </figure>
              </div>
            </div>
          </strong>
        </div>
        <figure className="mb-0 banner-image">
          <div className="bannerMain">
            <div className="leftEyeBanner"></div>
            <img src={BannerImage} alt="Banner" />
            <div className="rightEyeBanner"></div>
          </div>
        </figure>
      </div>
    </section>
  );
};
export default BannerSection;
