import React from "react";
import Banner1 from "../../banners/Banner1";
import Banner2 from "../../banners/Banner2";
import Banner3 from "../../banners/Banner3";
import Banner4 from "../../banners/Banner4";
import Banner5 from "../../banners/Banner5";
import Banner6 from "../../banners/Banner6";

const AdsBanners = () => {
  return (
    <>
      <style>
        {`
          .future_of_robotics,
          .robotics_footer_portion {
            display: none;
          }
        `}
      </style>
      <div className="container">
        <Banner1 />
        <br />
        <Banner2 />
        <br />
        <Banner3 />
        <br />
        <Banner4 />
        <br />
        <Banner5 />
        <br />
        <Banner6 />
        <br />
      </div>
    </>
  );
};

export default AdsBanners;
