import AboutSectionWithGrid from "../../sections/AboutWithGrid";
import ServiceSection from "../../sections/ServiceSection";
import ChooseSection from "../../sections/ChooseSection";
import TestimonialSection from "../../sections/TestimonialSection";
import ContactSection from "../../sections/ContactSection";
import Missionsection from "../../sections/MissionSection";
import Pricing from "../../sections/Pricing";
import MicroBotSection from "../../sections/MicroBotSection";
const Home = () => {
  return (
    <>
      {/* <BannerSection /> */}
      <AboutSectionWithGrid />
      <ChooseSection />
      <ServiceSection />
      <Pricing />
      <MicroBotSection />
      <Missionsection />
      <ContactSection />
      <TestimonialSection showLogoImage={true} />
    </>
  );
};

export default Home;
